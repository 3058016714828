<template>
  <div class="Menu-Head">
    <div class="w1200">
      <a href="/"
        ><div class="sxzk-logo">
          <img :src="this.languageList.logo" /></div
      ></a>

      <el-menu
        router
        :default-active="activeIndex"
        class="el-menu-demo el-menu-demo-pc"
        mode="horizontal"
        @select="handleSelect"
        @open="handleOpen"
        @close="handleClose"
        :collapse="isCollapse"
      >
        <el-menu-item index="/index">{{
          this.languageList.menu1
        }}</el-menu-item>
        <el-submenu index="2">
          <template slot="title">{{ this.languageList.menu2 }}</template>
          <div class="submenu-div">
            <router-link tag="a" target="_blank" to="/Product-WLZG"
              ><el-menu-item
                >{{ this.languageList.menu21 }}
              </el-menu-item></router-link
            >
            <router-link tag="a" target="_blank" to="/Product-BSYD"
              ><el-menu-item>{{
                this.languageList.menu22
              }}</el-menu-item></router-link
            >
            <router-link tag="a" target="_blank" to="/Product-XXNL"
              ><el-menu-item>{{
                this.languageList.menu23
              }}</el-menu-item></router-link
            >
            <router-link tag="a" target="_blank" to="/Product-XQTX"
            ><el-menu-item>{{
                this.languageList.menu24
              }}</el-menu-item></router-link
            >
            <router-link tag="a" target="_blank" to="/Product-JYL"
            ><el-menu-item>{{
                this.languageList.menu25
              }}</el-menu-item></router-link
            >
            <router-link tag="a" target="_blank" to="/Product-DTx"
            ><el-menu-item>{{
                this.languageList.menu26
              }}</el-menu-item></router-link
            >
          </div>
        </el-submenu>
        <el-menu-item index="/Solution">{{
          this.languageList.menu3
        }}</el-menu-item>
        <el-menu-item index="/futureLab">{{
          this.languageList.menu4
        }}</el-menu-item>
        <el-menu-item index="/AboutUs">{{
          this.languageList.menu5
        }}</el-menu-item>
      </el-menu>
      <div class="Language-div">
        <el-radio-group @change="Language" v-model="LanguageTypes">
          <el-radio :label="1">中文</el-radio>
          <span>·</span>
          <el-radio :label="2">English</el-radio>
        </el-radio-group>
      </div>
      <div class="el-dropdown-div">
        <el-dropdown trigger="click">
          <el-button type="primary">
            <i class="el-icon-s-fold el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-menu
              router
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              @select="handleSelect"
              @open="handleOpen"
              @close="handleClose"
              :collapse="isCollapse"
            >
              <el-menu-item index="/">{{
                this.languageList.menu1
              }}</el-menu-item>
              <el-submenu index="2">
                <template slot="title">{{ this.languageList.menu2 }}</template>

                <el-menu-item
                  ><a
                    href="https://wlzgof.eletell.com/mobile.html"
                    class="herf-a"
                    target="_blank"
                    >{{ this.languageList.menu21 }}
                  </a></el-menu-item
                >
                <el-menu-item>
                  <a
                    href="https://bsyd.eletell.com"
                    class="herf-a"
                    target="_blank"
                    >{{ this.languageList.menu22 }}</a
                  ></el-menu-item
                >

                <el-menu-item>
                  <a
                    href="https://kid.eletell.com"
                    class="herf-a"
                    target="_blank"
                    >{{ this.languageList.menu23 }}</a
                  ></el-menu-item
                >

                <el-menu-item>
                  <a
                      href="https://www.eletell.com/xqMobile.html"
                      class="herf-a"
                      target="_blank"
                  >{{ this.languageList.menu24 }}</a
                  ></el-menu-item
                >
              </el-submenu>
              <el-menu-item index="/Solution">{{
                this.languageList.menu3
              }}</el-menu-item>
              <el-menu-item index="/futureLab">{{
                this.languageList.menu4
              }}</el-menu-item>
              <el-menu-item index="/AboutUs">{{
                this.languageList.menu5
              }}</el-menu-item>
            </el-menu>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import ChineseJson from "../assets/json/Chinese-Json.json";
import EnglishJson from "../assets/json/English-Json.json";
export default {
  name: "HelloWorld",
  data() {
    return {
      activeIndex: this.$route.path,
      isCollapse: true,
      LanguageTypes: null,
      ChineseJson: JSON.parse(JSON.stringify(ChineseJson)), //中文配表
      EnglishJson: JSON.parse(JSON.stringify(EnglishJson)), //英文配表
      languageJson: [],
      languageList: [],

      screenWidth: document.body.clientWidth,
    };
  },
  props: {
    msg: String,
  },

  created() {
    if (localStorage.getItem("LanguageTypes") == undefined) {
      this.LanguageTypes = 1;
      this.IFType();
    } else {
      let types = localStorage.getItem("LanguageTypes");
      this.LanguageTypes = Number(types);
      this.IFType();
      // this.Language();
    }
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      window.screenWidth = document.body.clientWidth;
      that.screenWidth = window.screenWidth;
    };
    // console.log('that.screenWidth',that.screenWidth)
  },
  watch: {
    $route: "getPath",
    screenWidth(newVal) {
      console.log("newVal", newVal);
      this.windowIf(newVal);
    },
  },
  methods: {
    windowIf(newVal) {
      if (newVal <= 1024) {
        console.log(newVal, "窗口有变化");
        this.MenuIcon = true;
      }
    },
    IFType() {
      if (this.LanguageTypes == 1) {
        this.languageJson = this.ChineseJson;
        this.getList();
      } else {
        this.languageJson = this.EnglishJson;
        this.getList();
      }
    },
    getList() {
      // this.ChineseJson.fo
      this.languageJson.forEach((item) => {
        if (item.title == "MenuHead") {
          this.languageList = item;
        }
      });
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClick() {
      alert("button click");
    },
    getPath() {
      this.defaultUrl = this.$route.path;
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    //获取网页语言类型
    Language() {
      console.log(this.LanguageTypes);
      localStorage.setItem("LanguageTypes", this.LanguageTypes);
      this.$emit("func", this.LanguageTypes);
      console.log("e", this.LanguageTypes);
      this.IFType();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sxzk-logo {
  float: left;
  margin-top: 20px;
}
.Menu-Head {
}
.Menu-Head .el-menu-demo {
  width: 720px;
  float: right;
  border: 0;
}
.Menu-Head >>> .el-radio {
  color: #626e7f;
  font-size: 14px;
  line-height: 60px;
  margin-right: 15px;
}
.Language-div span {
  color: #626e7f;
}
.Menu-Head >>> .el-radio__inner {
  background-color: none !important;

  display: none;
}

.el-dropdown-menu {
  color: #ffffff;
  width: 130px;
  border: 0;
  margin-top: 8px;
  padding: 0;
  background: none;
  
}

.Menu-Head >>> .el-radio__input.is-checked + .el-radio__label {
  color: #ffffff;
}

.el-dropdown-div {
  position: absolute;
  z-index: 99999;
  top: 10px;
 
  right: 0px;
  display: none;
}
.el-dropdown-div .el-button--primary {
  background: none;
  border: 0;
  
  font-size: 20px;
}
.el-dropdown {
  vertical-align: top;
  
}
.herf-a {
  color: #ffffff;
}
.el-menu--horizontal > .el-menu-item {
  color: #ffffff;
}

.el-dropdown + .el-dropdown {
  margin-right: 25px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>

